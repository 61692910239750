<template>
  <v-card class="px-0" id="dialogProduct">
    <v-card-text class="px-0">

      <template v-if="product.categories[0] != null && product.categories[0].name == 'Mandala original'">
        <v-carousel class="mb-5">

          <v-carousel-item>
              <v-img :width="$vuetify.breakpoint.width < 600 ? '80%' : '65%'" 
                    class="mx-auto mt-5 cursor-pointer"
                    :class="product.isCircle ? 'img-rounded-dialog' : ''"
                    :aspect-ratio="1/1"
                    :src="baseUrl() + product.image"
                    @click="$router.push('/product/'+product._id)">
              </v-img>
          </v-carousel-item>

          <v-carousel-item
            v-for="(item,i) in product.gallery" :key="i"
            :src="baseUrl() + item"
            :to="'/product/'+product._id"
          ></v-carousel-item>

        </v-carousel>
      </template>
      <template v-else>
        <v-carousel class="mb-5" height="330">

          <v-carousel-item>
              <v-img width="100%" 
                    class="mx-auto"
                    :src="baseUrl() + product.image"
                    @click="$router.push('/product/'+product._id)">
              </v-img>
          </v-carousel-item>

          <v-carousel-item
            v-for="(item,i) in product.gallery" :key="i"
            :src="baseUrl() + item"
            :to="'/product/'+product._id"
          ></v-carousel-item>

        </v-carousel>
      </template>

      <v-toolbar class="px-3 toolbar-popup" color="transparent" height="70" elevation="0">
        <v-btn rounded :to="'/product/'+product._id">
          <v-icon small class="mr-1">mdi-collage</v-icon> 
          {{ product.gallery.length + 1 }} Photos
          <v-icon class="ml-2" small>mdi-arrow-right</v-icon> 
        </v-btn>
        <v-spacer/>
        <v-btn icon outlined @click="closeDialogProduct()" >
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>


      <router-link :to="'/product/'+product._id" style="color:unset;text-decoration:none;">
        <v-card-title class="title-dialog-product font-dancing">
          {{ product.name }}
        </v-card-title>
      </router-link>
      <br>
      <v-toolbar elevation="0" color="transparent" v-if="product.reproductible == true">
        <v-btn @click="showDialogRepro = true"  block elevation="0" outlined class="mr-1" color="teal">
          <v-icon class="mb-1 mr-1">mdi-basket-plus</v-icon> <b>Commander reproduction</b>
        </v-btn>
      </v-toolbar>
      <div class="px-5" v-html="nl2br(product.description)"></div>

      <br>
      <v-divider></v-divider>

      <div class="pl-2 mt-2">
      <CommentBlock :productId="product._id"></CommentBlock>
      </div>

    </v-card-text>


    <v-divider></v-divider>

    <v-card-actions class="pa-3">
      <template v-if="product.stock > 0">
      <span class="font-dancing ml-3" style="font-size:30px;">{{ product.price }}€</span>
      <v-spacer/>
      <v-btn color="green" dark elevation="0" @click="addToBasket(product)">
        <v-icon small class="mr-1">mdi-basket-plus</v-icon> Ajouter au panier
      </v-btn>
      </template>
      <template v-else>
        <v-spacer/>
        <small class="mr-3 grey--text">Ce produit n'est plus en stock</small>
        <!-- <v-btn color="orange" outlined dark elevation="0" to="/commande">
          <v-icon small class="mr-1">mdi-basket-plus</v-icon> Passer une commande
        </v-btn> -->
      </template>
    </v-card-actions>


    <DialogReproduction :product="product"
                      :showDialogRepro="showDialogRepro"
                      :closeDialogRepro="closeDialogRepro"></DialogReproduction>
  </v-card>
  
</template>


<script>
    import CommentBlock from '@/components/comment/CommentBlock.vue'
    import DialogReproduction from '@/components/main-layout/DialogReproduction.vue'
    import core from '../../plugins/core.js'
    //import { i18n } from 'vue-lang-router';

    export default {
        name: "dialog-product",
        components: {
          CommentBlock, DialogReproduction
        },
        props: ['product'],
        data: function () {
            return {
              showDialogRepro: false,
            };
        },
        mounted: function(){
        },
        methods: {
            closeDialogProduct(){
              this.$root.$emit('closeDialogProduct')
            },
            addToBasket(product){
              this.$store.dispatch('basket/addProduct', product)
              this.closeDialogProduct()
              this.$router.push("/panier")
            },
            closeDialogRepro(){ this.showDialogRepro = false },
            nl2br: function(str, is_xhtml){
              return core.nl2br(str, is_xhtml)
            },
            baseUrl(){ return core.baseUrl() }
        },
        computed:{
        }
    };
</script>